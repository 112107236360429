const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  lightGrayColor : '#ebebeb',
  brownColor : '#935e25',
  yellowColor : '#d2bf12',
  darkGrayColor : '#252525'
}

const variables = {
  familyBase: '"acumin-pro",sans-serif',
  familyHeader: '"acumin-pro-condensed",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.lightGrayColor,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;